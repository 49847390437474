<template>
  <v-app id="app">
    <pages-core-app-bar />

    <pages-core-view />

    <progress-bar-circular />

    <pages-core-footer />
    <span
      style="position: fixed; bottom: 0; left: 0"
      class="caption gris1--text"
      >v{{ pjson.version }}</span
    >
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",
  components: {
    ProgressBarCircular: () => import("@/components/ui/ProgressBarCircular"),
    PagesCoreAppBar: () => import("./components/core/AppBar"),
    PagesCoreFooter: () => import("./components/core/Footer"),
    PagesCoreView: () => import("./components/core/View"),
  },

  created() {
    this.$vuetify.theme.dark = true;
    this.pjson = require("@/../package.json");
  },
  data: () => ({
    pjson: {},
  }),

  beforeDestroy() {
    this.$vuetify.theme.dark = true;
  },
};
</script>
<style scoped lang="sass">
</style>
